<template>
  <div>
    <b-overlay :show="paymentLoading" rounded="lg" no-wrap style="z-index:99999;height:100vh !important;position:fixed !important">
      <template #overlay>
          <div id="loading-bg" class="d-flex align-items-center flex-column">
                  <img src="/login.png" alt="Logo" />
                  <b-spinner class="d-inline-block" label="Spinning"></b-spinner>
          </div>
      </template>
  </b-overlay>
  <b-card>
    <g-form @submit="save">
      <b-row class="p-0 mb-1">
        <b-col
          v-if="isCollectionVoucherRoute && currentBranch.isDistributionCostCentersActivated"
          md="5"
        >
          <b-button
            variant="relief-primary"
            class="btn-sm p-75"
            :disabled="!selectedItem.studentId || !selectedItem.originalValue || selectedItem.originalValue <= 0.00"
            @click="openCostCentersModal()"
          >
            {{ $t('distributeCostCenters') }}
            <feather-icon
              icon="SlackIcon"
              class="ml-50"
            />
          </b-button>
        </b-col>
        <b-col
          v-if="selectedItem.isPosted"
          cols="4"
        >
          <b-button
            :variant="'dark'"
            data-action-type="new"
            class="btn-sm p-75"
            @click="
              (v) => {
                this.$router.push({
                  name: 'vouchers-edit',
                  params: { id: selectedItem.postedVoucherId },
                });
              }
            "
          >
            {{ `${$t('voucherNum')} ${selectedItem.voucherCode}` }}
          </b-button>
        </b-col>
      </b-row>
      <hr class="border-bottom-primary border-darken-2">
      <b-row>
        <!-- code  -->
        <b-col
          v-show="selectedItem.code > 0"
          md="4"
        >
          <b-form-group>
            <g-field
              id="code"
              type="number"
              name="code"
              label-text="code"
              readonly
              :value.sync="selectedItem.code"
            />
          </b-form-group>
        </b-col>

        <!-- transaction date  -->
        <b-col
          v-if="!currentBranch.setDefaultDate"
          md="4"
        >
          <g-field
            :value="getDate(selectedItem.transactionDate)"
            label-text="date"
            disabled
            name="transactionDate"
          />
        </b-col>

        <b-col
          v-else
          md="4"
        >
          <g-picker
            :value.sync="selectedItem.transactionDate"
            label-text="date"
            name="transactionDate"
          />
        </b-col>

        <!-- transaction time  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              label-text="transactionTime"
              name="transactionTime"
              readonly
              :value.sync="selectedItem.transactionTime"
            />
          </b-form-group>
        </b-col>

        <!-- student  -->
        <b-col md="4">
          <b-form-group>
            <student-autocomplete
            name="student"
            rules="required"
            :disabled="selectedItem.financialTransactionAllocations.length > 0"
            :value.sync="selectedItem.studentId"
            url="Students/getStudentsForVoucher"
            :display-item="{
              id: selectedItem.studentId,
              uid: selectedItem.studentId,
              arabicName: selectedItem.studentArabicName,
            }"
            @change:action="(val) =>  filterFromStudents(val)"
          />
          </b-form-group>
        </b-col>
        <!-- guardian  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              id="guardianName"
              ref="guardianName"
              name="guardianName"
              label-text="guardianName"
              readonly
              :value.sync="guardianName"
            />
          </b-form-group>
        </b-col>

        <!-- guardian mobile number -->
        <b-col md="4">
          <b-form-group>
            <g-field
              id="guardianMobile"
              ref="guardianMobile"
              name="guardianMobile"
              label-text="guardianMobile"
              readonly
              :value.sync="guardianMobileNumber"
            />
          </b-form-group>
        </b-col>

        <!-- Remaining Balance  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              id="remainingBalance"
              ref="remainingBalance"
              name="remainingBalance"
              label-text="studentBalance"
              readonly
              :value.sync="remainingBalance"
            />
          </b-form-group>
        </b-col>

        <!-- voucher value  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              field="number"
              label-text="value"
              type="number"
              rules="required"
              :disabled="
                selectedItem.financialTransactionAllocations.length > 0
              "
              :value.sync="selectedItem.voucherValue"
            />
          </b-form-group>
        </b-col>

        <!-- original value -->
        <b-col md="4">
          <b-form-group>
            <g-field
              field="number"
              label-text="originalValue"
              type="number"
              readonly
              :value.sync="selectedItem.originalValue"
            />
          </b-form-group>
        </b-col>

        <!-- tax value -->
        <b-col md="4">
          <b-form-group>
            <g-field
              field="number"
              type="number"
              label-text="taxValue"
              readonly
              :value.sync="selectedItem.taxValue"
            />
          </b-form-group>
        </b-col>

        <!-- payment method  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              field="select"
              label="arabicName"
              label-text="Payment Method"
              name="paymentMethod"
              :options="paymentWays"
              :dir="isRight ? 'rtl' : 'ltr'"
              :value.sync="selectedItem.paymentMethod"
            />
          </b-form-group>
        </b-col>

        <!-- safe payment  -->
        <b-col v-if="selectedItem.paymentMethod == 'cash'" md="4">
          <b-form-group>
            <g-field
              field="select"
              label="arabicName"
              label-text="safeName"
              name="safePayment"
              :options="safes"
              :dir="isRight ? 'rtl' : 'ltr'"
              :value.sync="selectedItem.safeId"
            />
          </b-form-group>
        </b-col>

        <!-- other payments  -->
        <b-col v-if="selectedItem.paymentMethod == 'other'" md="4">
          <b-form-group>
            <g-field
              field="select"
              label="arabicName"
              label-text="PaymentMethods"
              name="PaymentMethods"
              :options="payments"
              :dir="isRight ? 'rtl' : 'ltr'"
              :value.sync="selectedItem.paymentMethodId"
              @change="
                (v) => {
                  selectedItem.bankId = v.bankId;
                }
              "
            />
          </b-form-group>
        </b-col>

        <!-- bank  -->
        <b-col v-if="selectedItem.paymentMethod == 'other'" md="4">
          <b-form-group>
            <g-field
              field="select"
              label="arabicName"
              label-text="bankName"
              name="bank1"
              disabled
              :options="Banks"
              :dir="isRight ? 'rtl' : 'ltr'"
              :value.sync="selectedItem.bankId"
            />
          </b-form-group>
        </b-col>

        <!-- setDefaultDate  -->
        <b-col
          v-if="this.$route.params.type === 'collection' ||
            this.$route.name === 'collection-transactions-edit' ||
            this.$route.name === 'collection-transactions-new'"
          md="4"
        >
          <b-form-group :label="$t('advanceRevenue')">
            <b-form-checkbox
              v-model="selectedItem.isAdvanceRevenue"
              class="custom-control-primary"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- notes -->
      <b-row class="pt-1">
        <b-col md="12">
          <b-form-group :label="$t('notes')">
            <b-form-textarea
              id="textarea"
              v-model="selectedItem.notes"
              label-text="Notes"
              rows="2"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-modal
        no-close-on-backdrop
        ref="inv-modal"
        cancel-variant="outline-secondary"
        centered
        size="xl"
        hide-footer
        :title="$t('allInvoice')"
      >
        <b-row>
          <b-col md="4">
            <h2 class="text-center">
              {{ $t("allocationValue") }} :
              <span class="text-primary">{{
                id
                  ? selectedItem.voucherValue
                  : selectedItem.voucherValue
              }}</span>
            </h2>
          </b-col>
          <b-col md="4">
            <h2 class="text-center">
              {{ $t("allocatedValue") }} :
              <span class="text-primary">{{ allPaid }}</span>
            </h2>
          </b-col>
          <b-col md="4">
            <h2 class="text-center">
              {{ $t("remining") }} :
              <span class="text-primary">{{
                id
                  ? (selectedItem.voucherValue - lastPaid) - allPaid
                  : selectedItem.voucherValue - allPaid
              }}</span>
            </h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-checkbox
              v-model="helper.showNotCompletedOnly"
              name="check-button"
              switch
              inline
              class="mt-1 mb-2"
            >
              {{ $t('showNotAllocatedCompletelyOnly') }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <g-table
          ref="items-table"
          :items="invoiceProvider"
          :columns="tableColumns"
          v-model="itemsArray"
          :is-busy="isTableBusy"
          :noAction="true"
          perPage="25"
          :totalRows="totalRows"
          :createButton="{ visiable: false }"
          :excelButton="{ visiable: false }"
          :pdfButton="{ visiable: false }"
          :searchInput="{ visiable: false }"
          :tbody-tr-class="
            (item) => {
              if (item && item.net - item.paid === 0) return 'table-success';
            }
          "
        >
          <template #actions="{ item }">
            <g-field
              class="mb-0"
              :value.sync="item.voucherValue"
              type="number"
              :disabled="item.net - item.paid === 0"
              :rules="`min_value:0|max_value:${item.remainder}`"
              :shortDesc="true"
              @keyup="
                (v) => {
                  updateFinancialTransactionAllocations(item, itemsArray);
                  if (item.voucherValue > item.remainder) {
                    moreThan = true;
                  } else {
                    moreThan = false;
                  }
                }
              "
              size="sm"
              :style="{ width: '100px' }"
            />
          </template>
        </g-table>
        <hr />
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
            @click="() => {
              if (!isValidVoucherValuePaid()) { return }
              save()
            }"
            data-action-type="save"
            :disabled=" allPaid === 0 || moreThan"
            variant="gradient-primary"
            v-permission="$route.meta.permission"
          >
            <feather-icon
              icon="CheckIcon"
              class="mr-50"
            />
            {{ $t("save") }}
          </b-button>
        </b-col>
      </b-modal>

      <!-- cost centers modal -->
      <b-modal
        ref="costCenters-modal"
        no-close-on-backdrop
        cancel-variant="secondary"
        centered
        size="lg"
        hide-footer
        :title="$t('distributeCostCenters')"
      >
        <b-row>
          <b-col md="12">
            <div class="d-flex flex-column mb-2">
              <label class="main-font-size"><span class="modal-indicator">{{ $t('shareToAccount') }}</span> {{ isRight ? selectedStudent.accountNameAr : selectedStudent.accountNameEn }}</label>
              <label class="main-font-size"><span class="modal-indicator">{{ $t('shareCreditWithValue') }}</span> {{ fraction(selectedItem.originalValue) }} </label>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="5">
            <g-field
              :value.sync="collectionCostCenter.CostCenterId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="costCenter"
              field="select"
              name="costCenter"
              :options="costCenters"
              label="arabicName"
            />
          </b-col>
          <b-col md="2">
            <g-field
              :value.sync="collectionCostCenter.value"
              :shortDesc="true"
              rules="min_value:0"
              type="number"
              label-text="value"
            />
          </b-col>
          <b-col md="5">
            <g-field
              id="notes"
              :value.sync="collectionCostCenter.notes"
              label-text="notes"
              name="notes"
            />
          </b-col>
          <b-col
            md="12"
          >
            <b-button
              class="mb-1 fa-pull-left"
              data-action-type
              variant="gradient-primary"
              :disabled="!collectionCostCenter.CostCenterId || !collectionCostCenter.value || collectionCostCenter.value <= 0"
              @click="addCostcenter(collectionCostCenter)"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              {{ $t('addToDistributionList') }}
            </b-button>
          </b-col>
        </b-row>
        <label style="font-size: 16px">
          <feather-icon
            icon="ListIcon"
            class="mr-50"
            size="18"
            stroke="#7367f0"
          />
          {{ $t('costCenterDistributionList') }}
        </label>
        <b-row class="p-0">
          <b-col>
            <b-table
              :items="selectedItem.costCenterLines"
              :fields="costcenterTableColumns"
              primary-key="id"
              show-empty
              striped
              hover
              foot-clone
              stickyColumn
              fixed
              class="position-relative"
              :empty-text="$t('noMatchingRecordsFound')"
            >
              <!-- Column: Name -->
              <template #cell(arabicName)="data">
                <span>
                  {{ data.item.arabicName }}
                </span>
              </template>
              <template #head(actions)>
                <span />
              </template>
              <template #cell(actions)="data">
                <div class="text-nowrap">
                  <feather-icon
                    icon="TrashIcon"
                    stroke="red"
                    @click="
                      (v) => {
                        removeCostCenter(data.item);
                      }
                    "
                  />
                </div>
              </template>
              <template #foot(costCenterCode)>
                <span />
              </template>
              <template #foot(costCenterArabicName)>
                <span />
              </template>
              <template #foot(value)>
                <span> {{ fraction(totalCostcenters) }} </span>
              </template>
              <template #foot(notes)>
                <span> {{ $t('remining') }}  {{ fraction(reminderedCostcenters) }}</span>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="d-flex justify-content-end py-1 pb-2"
          >
            <b-button
              variant="outline-primary"
              @click="closeCostCentersModal()"
            >
              <feather-icon
                icon="CheckCircleIcon"
                class="mr-50"
              />
              {{ $t('approveDistribution') }}
            </b-button>
          </b-col>
        </b-row>
      </b-modal>

      <!-- operations -->
      <b-row>
        <b-col
          md="4"
          class="cancel-allocation d-flex justify-content-start"
        >
          <b-button
            v-if="id && selectedItem.financialTransactionAllocations.length > 0"
            variant="adn"
            @click="
              () => {
                selectedItem.financialTransactionAllocations = [];
                save('cancel');
              }
            "
          >
            <feather-icon
              icon="ZapOffIcon"
              class="mr-50"
            />
            {{ $t("cancelAlloucation") }}
          </b-button>
        </b-col>

        <b-col
          md="8"
          class="d-flex justify-content-end"
        >
        <b-button
        v-if='this.currentBranch.enablePaymentMachen'
        :disabled="!selectedItem.studentId || !selectedItem.paymentMethod || !selectedItem.safeId || !selectedItem.voucherValue"
            v-permission="$route.meta.permission"
            data-action-type="save"
            variant="primary"
            @click="beforePaymentValidation"
          >
            {{ $t("payAndSave") }}
          </b-button>
          <b-button
            v-permission="$route.meta.permission"
            type="submit"
            data-action-type="save"
            variant="gradient-primary"
            style="margin: 0 10px;"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            {{ $t("save") }}
          </b-button>
          <b-button
            v-permission="$route.meta.permission"
            :disabled="!selectedItem.voucherValue"
            style="margin: 0 10px;"
            variant="outline-primary"
            data-action-type="saveAndPrint"
            @click="save('saveAndPrint')"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-50"
            />
            {{ $t("saveAndPrint") }}
          </b-button>
          <b-button
            @click="
              () => {
                if (!isValidPayment()) { return }
                if (isCollectionVoucherRoute && !isValidCostCenterBalance()) return;
                openInvModal();
                this.allPaid = 0;
              }
            "
            data-action-type="saveAndCustomize"
            variant="gradient-primary"
            :disabled="!selectedItem.studentId || !selectedItem.paymentMethod"
            v-permission="$route.meta.permission"
            v-if="
              this.$route.params.type === 'collection' ||
              this.$route.name === 'collection-transactions-edit' ||
              this.$route.name === 'collection-transactions-new'
            "
          >
            <feather-icon
              icon="ZapIcon"
              class="mr-50"
            />
            {{ $t("saveAndCustomize") }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
  </div>
</template>

<script>
import GTable from '@/pages/Shared/Table.vue';
import { paymentWays } from '@/libs/acl/Lookups';
import reportMixin from '@/mixin/reportMixin';
import StudentAutocomplete from '@/components/StudentAutoComplete.vue';
import { v4 as uuidv4 } from 'uuid';
import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';
import { domain, TOKEN_KEY } from '@/libs/acl/config';

export default {
  components: {
    GTable,
    StudentAutocomplete
  },
  mixins: [
    reportMixin,
  ],
  props: ['id'],
  data() {
    return {
      items: [],
      selectedItem: {
        costCenterLines: [],
        transactionDate: '',
        voucherType: '',
        paymentMethod: 'cash',
        financialTransactionAllocations: [],
        isAdvanceRevenue: false,
        notes: ''
      },
      guardians: [],
      students: [],
      bankName: null,
      Banks: [],
      safes: [],
      payments: [],
      paymentMethods: [],
      nationalities: [],
      paymentWays: paymentWays,
      pathName: this.$route.name,
      guardianName: null,
      guardianMobileNumber: null,
      isTaxAppliedOnStudent: null,
      remainingBalance: 0,
      financialTransactionAllocations: [],
      filter: {
        transactionType: '',
        itemTransactionPaymentNature: '3',
        id: null,
        studentId: null,
        storeId: null,
        ItemId: null,
        FromDate: null,
        toDate: null,
        orderClause: null,
        category: '',
        Code: null
      },
      isTableBusy: false,
      totalRows: 0,
      totalRemainder: 0,
      totalPaid: 0,
      totalDiscount: 0,
      totalNet: 0,
      totalAllocatedValue: 0,
      itemsArray: [],
      hideActions: false,
      moreThan: false,
      allPaid: 0,
      lastPaid: 0,
      checkPerm: false,
      transactionDate: this.today,
      alldata: [],
      helper: {
        showNotCompletedOnly: false
      },
      costCenters: [],
      collectionCostCenter: {
        CostCenterId: null,
        value: 0,
        notes: ''
      },
      selectedStudent: {
        accountId: null,
        accountNameAr: '',
        accountNameEn: '',
        isDistributable: false
      },
      generatedGuid: null,
      paymentData: {},
      paymentIsDone: false,
      paymentLoading: false,
    };
  },
  computed: {
    paymentFlag() {
      return this.paymentIsDone;
    },
    tableColumns() {
      return [
        {
          key: 'code',
          field: 'code',
          label: this.$t('invoiceCode'),
          sortable: true,
        },
        {
          key: 'transactionDate',
          field: 'transactionDate',
          label: this.$t('invoiceDate'),
          sortable: true,
        },
        {
          key: 'lineSerial',
          field: 'lineSerial',
          label: this.$t('serialIndicator'),
          sortable: true,
        },
        {
          key: this.isRight ? 'serviceName' : 'serviceName',
          field: this.isRight ? 'serviceName' : 'serviceName',
          label: this.$t('serviceName'),
          sortable: true,
        },
        {
          key: 'net',
          field: 'net',
          label: this.$t('net'),
          sortable: true,
          footer: () => this.fraction(this.totalNet)
        },
        {
          key: 'allocatedValue',
          field: 'allocatedValue',
          label: this.$t('paid'),
          sortable: false,
          thClass: this.filter.transactionType === 'quotation' ? 'd-none' : '',
          tdClass: this.addTdClass,
          footer: () => this.fraction(this.totalPaid)
        },
        {
          key: 'allocateDiscount',
          field: 'allocateDiscount',
          label: this.$t('discount'),
          sortable: false,
          thClass: this.filter.transactionType === 'quotation' ? 'd-none' : '',
          tdClass: this.addTdClass,
          footer: () => this.fraction(this.totalDiscount)
        },
        {
          key: 'remainder',
          field: 'remainder',
          label: this.$t('remaindered'),
          sortable: false,
          thClass: this.filter.transactionType === 'quotation' ? 'd-none' : '',
          tdClass: this.addTdClass,
          footer: () => this.fraction(this.totalRemainder)
        },
        {
          key: 'actions',
          label: this.$t('actions'),
          sortable: false,
          thStyle: { width: '200px' },
        },
      ];
    },

    costcenterTableColumns() {
      return [
        { key: 'costCenterCode', label: this.$t('code'), sortable: false },
        {
          key: this.isRight ? 'costCenterArabicName' : 'costCenterEnglishName',
          label: this.$t('name'),
          sortable: false,
        },
        {
          key: 'value',
          label: this.$t('value'),
          sortable: false,
          type: 'number',
          footer: () => this.fraction(this.totalVal),
        },
        { key: 'notes', label: this.$t('notes'), sortable: false },
        { key: 'actions' },
      ];
    },

    totalCostcenters() {
      return this.selectedItem.costCenterLines.reduce((sum, item) => {
        sum += parseFloat(item.value);
        return sum;
      }, 0);
    },

    reminderedCostcenters() {
      return this.fraction(this.selectedItem.originalValue) - this.fraction(this.totalCostcenters) || 0;
    },

    isCollectionVoucherRoute() {
      return this.pathName === 'collection-transactions-new' || this.pathName === 'collection-transactions-edit'
    }
  },
  watch: {
    'selectedItem.paymentMethod'(newval) {
      if (newval === 'cash') {
        this.selectedItem.bankId = null;
        this.selectedItem.paymentMethodId = null;
        this.bankName = null
      } else if (newval === 'other') {
        this.selectedItem.safeId = null;
      }
    },

    'selectedItem.voucherValue'(value) {
      if (value === '') {
        this.selectedItem.originalValue = 0;
        this.selectedItem.taxValue = 0;
      }
      this.calculateTaxAndOrifinalValue(value);
    },

    isTaxAppliedOnStudent() {
      this.calculateTaxAndOrifinalValue(this.selectedItem.voucherValue);
    },

    'selectedItem.financialTransactionAllocations'(oldval, newval) {
      newval.forEach(data => {
        const filterdItem = this.itemsArray.find((val) => val.lineSerial === data.lineSerial && val.serviceId === data.serviceId);
        const number = Number(data.allocatedValue) ? Number(data.voucherValue) - Number(data.allocatedValue) : Number(data.voucherValue);
        const mainVal = this.id ? (this.selectedItem.voucherValue - this.lastPaid) - this.allPaid : this.selectedItem.voucherValue - this.allPaid
        if ((Number(filterdItem.remainder) !== 0 && (number > Number(filterdItem.remainder) || Number(data.value) > Number(filterdItem.remainder))) || mainVal < 0) {
          this.moreThan = true;
          this.doneAlert({ text: this.$t('allocationValueIsMoreThanMainValue'), type: 'warning' });
        } else if ((number <= Number(filterdItem.remainder) && Number(filterdItem.remainder) !== 0) || mainVal < 0) {
          this.moreThan = false;
        }
      });
    },

    'helper.showNotCompletedOnly'(isSome) {
      this.invoiceProvider = isSome
                        ? this.itemsArray.filter((x) => parseFloat(x.remainder) > 0)
                        : this.itemsArray
    }
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
 async mounted() {
    const id = this.$route.query.studentId;
    if (id > 0) {
      this.selectedItem.studentId = id;
      this.selectedItem.studentArabicName = this.$route.params.name;
    }

    if (this.$route.name === 'payment-transactions-new' || this.$route.name === 'payment-transactions-edit') {
      this.selectedItem.voucherType = 'paymentDebenture';
      this.selectedItem.nature = 'prevBalance';
    } else if (this.$route.name === 'collection-transactions-new' || this.$route.name === 'collection-transactions-edit') {
      this.selectedItem.voucherType = 'collectionDebenture'
      this.selectedItem.nature = 'prevBalance';
    }

    this.selectedItem.transactionDate = this.today;
    this.selectedItem.chequeDate = this.today;
    this.selectedItem.transactionTime = this.getTime();
    if (this.id > 0) {
     await this.getData();
    }
    this.loadObj();
  },
  created() {
    if (this.currentBranch.enablePaymentMachen && this.profile.enablePaymentMachen) {
    const token = window.localStorage.getItem(TOKEN_KEY);
        this.hubConnection = new HubConnectionBuilder()
          .withUrl(`${domain}/hubs/Payment?authorization=${token}`, {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
          })
          .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000])
          .build();
          this.hubConnection
          .start()
          .then(() => {
            this.hubConnection.on('Completepayment', (uuid, referenceNumber, message) => {
              this.paymentData = referenceNumber;
              if (referenceNumber && Number(referenceNumber) !== -1) {
                this.paymentIsDone = true;
                this.paymentLoading = false;
                if (this.selectedItem.notes) {
                  this.selectedItem.notes = ` ${this.selectedItem.notes} / ${referenceNumber}`;
                } else {
                  this.selectedItem.notes = `${referenceNumber}`;
                }
                this.save();
              } else {
                this.paymentIsDone = false;
                this.paymentLoading = false;
                this.doneAlert({ text: message, type: 'error' });
              }
            });
          });
        }
  },
  methods: {
    async beforePaymentValidation() {
    this.generateGuid();
    try {
         await this.get({ url: `CollectionVouchers/pay/${this.generatedGuid}/${this.selectedItem.voucherValue}` });
          this.paymentLoading = true;
        return true;
    } catch (e) {
          this.paymentLoading = false;
        return false;
      }
    },
    generateGuid() {
      this.generatedGuid = uuidv4();
    },
  startHubConnection() {
    const token = window.localStorage.getItem(TOKEN_KEY);
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${domain}/hubs/Payment?authorization=${token}`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000])
      .build();
      this.hubConnection
      .start()
      .then(() => {
        this.hubConnection.on('Completepayment', (data) => {
          this.paymentData = data;
        });
      });
    },
    endHubConnection() {
      this.hubConnection.onclose();
    },
  beforeDestroy() {
    if (this.currentBranch.enablePaymentMachen && this.profile.enablePaymentMachen) {
    this.hubConnection.onclose();
    }
  },
    validateYear(date) {
      if (this.getDate(this.fiscalYearStart) > this.getDate(date) || this.getDate(date) > this.getDate(this.fiscalYearEnd)) {
      this.doneAlert({
          type: 'error',
          text: this.$t('youdonothaveaccesstothisyear'),
        });
        return false;
      }
      return true;
    },
    invoiceProvider(ctx, callback) {
      const {
        currentPage, perPage, sortBy, sortDesc
      } = ctx;
      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      let params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;
      params += this.getFilterObj(this.filter);
      if (this.filter.studentId === null) {
        this.filter.studentId = this.selectedItem.studentId;
      }
      this.isTableBusy = true;
      this.get({ url: `Invoices/services/${this.filter.studentId}${params}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount;
          this.totalNet = 0;
          this.totalRemainder = 0;
          this.totalPaid = 0;
          this.totalDiscount = 0;
          data.forEach((item) => {
            item.paid = 0;
            this.totalPaid += (item.paid + item.allocatedValue);
            this.totalNet += (item.net - item.returnTotal);
            item.allocatedValue = item.allocatedValue ? item.allocatedValue : 0;
            this.totalDiscount = item.allocateDiscount ? item.allocateDiscount : 0;
            this.totalRemainder += (item.net - (item.paid + item.allocatedValue + item.allocateDiscount + item.returnTotal));
            item.paid = this.fraction(item.paid + item.allocatedValue);
            item.remainder = this.fraction(item.net - (item.allocatedValue + item.allocateDiscount + item.returnTotal));
            item.transactionDate = this.getDate(item.transactionDate);
            item.net = Number(this.fraction(item.net - item.returnTotal));
            item.value = 0;
          });
          this.itemsArray = data;
          if (this.alldata.length) {
            this.selectedItem.financialTransactionAllocations.forEach(elem => {
              const selectedItem = this.alldata.find((val) => val.id === elem.itemTransactionId);
              const selectedmain = data.find((val) => val.id === selectedItem.id);
              if (!selectedmain) return data.push(selectedItem);
            });
          }
          callback(data);
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      return null;
    },

    loadObj() {
      // this.getStudents();
      this.getBanks();
    },

    updateFinancialTransactionAllocations(item, items) {
      this.allPaid = 0;
      items.forEach(row => {
        this.allPaid += parseFloat(row.voucherValue) || 0;
      });
      const selectedItem = this.selectedItem.financialTransactionAllocations.find((val) => val.lineSerial === item.lineSerial && val.serviceId === item.serviceId && val.itemTransactionId === item.id);
      if (!selectedItem && item.voucherValue > 0) {
        this.selectedItem.financialTransactionAllocations.push(
          {

            lineSerial: item.lineSerial,
            serviceId: item.serviceId,
            allocatedValue: item.allocatedValue,
            itemTransactionId: item.id,
            value: item.voucherValue,
            financialTransactionId: this.id ? this.id : 0
          }
        )
      } else if (item.voucherValue === 0 && selectedItem) this.selectedItem.financialTransactionAllocations = this.selectedItem.financialTransactionAllocations.filter((val) => val !== selectedItem);
      else if (selectedItem) {
        const indx = this.selectedItem.financialTransactionAllocations.indexOf(selectedItem);
        this.selectedItem.financialTransactionAllocations.splice(indx, 1);
        this.selectedItem.financialTransactionAllocations.push({
          lineSerial: item.lineSerial,
          serviceId: item.serviceId,
          itemTransactionId: item.id,
          value: item.voucherValue,
          financialTransactionId: this.id ? this.id : 0,
          allocatedValue: item.allocatedValue,

        });
      }
      this.mainVal = 0;
      this.selectedItem.financialTransactionAllocations.forEach(data => {
        data.voucherValue = data.voucherValue ? data.voucherValue : 0;
        this.mainVal += data.voucherValue
        this.totalAll = Number(this.selectedItem.voucherValue) - this.mainVal;
        this.totalAllocatedValue = this.totalAll;
        var val = this.id ? (Number(this.selectedItem.voucherValue) - this.lastPaid) - this.allPaid : Number(this.selectedItem.voucherValue) - this.allPaid;
        if (val < 0) {
          this.moreThan = true;
          this.doneAlert({ text: this.$t('allocationValueIsMoreThanMainValue'), type: 'warning' });
        } else {
          this.moreThan = false;
        }
      });
     // this.closeInvModal();
    },

    openInvModal() {
      this.helper.showNotCompletedOnly = false;
      this.totalAllocatedValue = this.selectedItem.voucherValue;
      if (this.id > 0 && this.selectedItem.studentId) {
        this.getAllInv();
      } else {
        this.$refs['inv-modal'].show();
      }
    },
    getAllInv() {
      this.get({ url: `Invoices/services/${this.selectedItem.studentId}?pageNumber=${1}&pageSize=${50}&studentId=${this.selectedItem.studentId}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount;
          this.totalNet = 0;
          this.totalRemainder = 0;
          this.totalPaid = 0;
          this.totalDiscount = 0;
          data.forEach((item) => {
            item.paid = 0;
            this.totalPaid += (item.paid + item.allocatedValue);
            this.totalNet += (item.net - item.returnTotal);
            item.allocatedValue = item.allocatedValue ? item.allocatedValue : 0;
            this.totalDiscount = item.allocateDiscount ? item.allocateDiscount : 0;
            this.totalRemainder += (item.net - (item.paid + item.allocatedValue + item.allocateDiscount + item.returnTotal));
            item.paid = this.fraction(item.paid + item.allocatedValue);
            item.remainder = this.fraction(item.net - (item.allocatedValue + item.allocateDiscount + item.returnTotal));
            item.transactionDate = this.getDate(item.transactionDate);
            item.net = this.fraction((item.net - item.returnTotal));
            item.value = 0;
          });
          this.alldata = data;
          // this.$refs['items-table'].refreshTable();
        }).then(() => {
          this.$refs['inv-modal'].show();
        })
        .catch(() => {
          this.isTableBusy = false;
        });
      return null;
    },
    calculateTaxAndOrifinalValue(baseValue) {
      if (this.currentBranch.applyTaxOnVoucher) {
      if (this.isTaxAppliedOnStudent) {
        this.selectedItem.originalValue = ((baseValue || 0) / 1.15).toFixed(2);
        this.selectedItem.taxValue = ((baseValue || 0) - (this.selectedItem.originalValue || 0)).toFixed(2)
      } else {
        this.selectedItem.originalValue = this.selectedItem.voucherValue || 0;
        this.selectedItem.taxValue = 0;
      }
    } else {
        this.selectedItem.originalValue = this.selectedItem.voucherValue || 0;
        this.selectedItem.taxValue = 0;
      }
    },

    filterFromStudents(selectedStudent) {
      this.selectedStudent = selectedStudent;
      this.filter.studentId = selectedStudent.id;
      // then display data needed for him
      this.guardianName = this.isRight ? selectedStudent.guardianNameAr : selectedStudent.guardianNameEn;
      this.guardianMobileNumber = selectedStudent.guardianMobile;
      this.isTaxAppliedOnStudent = selectedStudent.isTaxable
      this.remainingBalance = selectedStudent.remainingBalance;
    },

    closeInvModal() {
      this.allPaid = 0;
    },
    getBanks() {
      this.get({ url: 'Safes' }).then((data) => {
        this.safes = data;
      });
      this.get({ url: 'Banks' }).then((data) => {
        this.Banks = data;
      });
      if (this.id > 0 && this.selectedItem.isElectronicPayment) {
        this.get({ url: 'PaymentMethods' }).then((data) => {
        this.payments = data;
      })
      } else {
      this.get({ url: 'PaymentMethods/getAllNonElectronic' }).then((data) => {
        this.payments = data;
      })
    }
    },

   async getData() {
      if (this.isCollectionVoucherRoute) {
      await this.get({
          url: 'CollectionVouchers',
          id: this.$route.params.id,
        }).then((data) => {
          data.financialTransactionAllocations.forEach(item => {
              this.lastPaid += item.value;
              item.value = 0 + item.value;
            });
          this.selectedItem = data;
          this.selectedItem.transactionTime = this.getTime(this.selectedItem.transactionTime);
        }).then(() => {
          this.guardianName = this.selectedItem.guardianName
          this.guardianMobileNumber = this.selectedItem.guardianMobileNumber
          this.isTaxAppliedOnStudent = this.selectedItem.isTaxableStduent
          this.remainingBalance = this.selectedItem.remainingBalance
          this.selectedStudent = {
            accountId: this.selectedItem.studentAccountId || null,
            accountNameAr: this.selectedItem.studentAccountNameAr || '',
            accountNameEn: this.selectedItem.studentAccountNameEn || '',
            isDistributable: this.selectedItem.isAccountDistributable || false
          }
        })
      } else {
       await this.get({
          url: 'PaymentVouchers',
          id: this.$route.params.id,
        }).then((data) => {
          this.selectedItem = data;
          this.selectedItem.financialTransactionAllocations = []
        }).then(() => {
          this.guardianName = this.selectedItem.guardianName
          this.guardianMobileNumber = this.selectedItem.guardianMobileNumber
          this.isTaxAppliedOnStudent = this.selectedItem.isTaxableStduent
          this.remainingBalance = this.selectedItem.remainingBalance
        })
      }
    },

    isValidVoucherValuePaid() {
      if (this.selectedItem.voucherValue - this.allPaid > 0) {
        this.doneAlert({ text: this.$t('amountMustBeAllocated'), type: 'warning' });
        return false
      }

      // all is well
      return true
    },

    isValidPayment() {
       // if no payment method selected
       if (!this.selectedItem.paymentMethod) {
        this.doneAlert({ text: this.$t('shouldSelectPaymentMethod'), type: 'warning' });
        return false
      }

      // if cash payment method selected and not select a safe
      if (this.selectedItem.paymentMethod === 'cash' && !this.selectedItem.safeId) {
        this.doneAlert({ text: this.$t('shouldSelectSafe'), type: 'warning' });
        return false
      }

      // if other payment method selected and not select a bank
      if (this.selectedItem.paymentMethod === 'other' && !this.selectedItem.paymentMethodId) {
        this.doneAlert({ text: this.$t('shouldSelectPaymentType'), type: 'warning' });
        return false
      }

      // all is well
      return true
    },

    isValidCostCenterBalance() {
      if (this.selectedItem.costCenterLines.length > 0 && this.reminderedCostcenters !== 0) {
        this.doneAlert({ text: this.$t('youShouldDistributeAllAmount'), type: 'error', timer: 6000 });
        return false;
      }
      return true;
    },

    validateBeforeSave() {
      if (!this.selectedItem.studentId) return false;
      if (!this.validateYear(this.selectedItem.transactionDate)) return false;
      if (!this.isValidPayment()) return false;
      if (this.isCollectionVoucherRoute && !this.isValidCostCenterBalance()) return false;

      return true;
    },

    save(type) {
      if (!this.validateBeforeSave()) return;

      this.selectedItem.branchId = this.branchId;
      if (this.isCollectionVoucherRoute) {
        if (this.id > 0) {
          this.update({
            url: 'CollectionVouchers',
            data: this.selectedItem,
            id: this.id,
          })
            .then(() => {
              if (type === 'cancel') {
                this.doneAlert({ text: this.$t('allocationCancelledSuccessfully') });
              } else {
                this.doneAlert({ text: this.$t('updatedSuccessfully') });
                if (type === 'saveAndPrint') this.print(this.selectedItem.id);
              }
              this.$router.push({ name: 'collection-transactions' });
            });
        } else {
          this.selectedItem.transactionTime = this.getTime();
          this.create({
            url: 'CollectionVouchers',
            data: this.selectedItem,
          })
            .then((dataId) => {
              this.doneAlert({
                text: this.$t('savedSuccessfully'),
              });
              if (type === 'saveAndPrint') this.print(dataId);
              this.$router.push({ name: 'collection-transactions' });
            });
        }
      } else if (!this.isCollectionVoucherRoute) {
        if (this.id > 0) {
          this.update({
            url: 'PaymentVouchers',
            data: this.selectedItem,
            id: this.id,
          })
            .then(() => {
              this.doneAlert({ text: this.$t('savedSuccessfully') });
              if (type === 'saveAndPrint') this.print(this.selectedItem.id);
              if (type === 'cancel') {
                window.location.reload();
              } else {
                this.$router.push({ name: 'payment-transactions' });
              }
            });
        } else {
          this.selectedItem.transactionTime = this.getTime();
          this.create({
            url: 'PaymentVouchers',
            data: this.selectedItem,
          })
            .then((dataId) => {
              this.doneAlert({
                text: this.$t('savedSuccessfully'),
              });
              if (type === 'saveAndPrint') this.print(dataId);
              this.$router.push({ name: 'payment-transactions' });
            });
        }
      }
    },

    print(itemId) {
      const reportName = this.isRight ? 'FinancialTransaction-ar' : 'FinancialTransaction-en';
      const printedItem = {
        id: itemId,
        voucherType: this.selectedItem.voucherType === 'collectionDebenture' ? 0 : 1
      }
      this.printReport(reportName, printedItem);
    },

    openCostCentersModal() {
      // check that student must have related to account
      if (!this.selectedStudent.accountId) {
        this.doneAlert({ text: this.$t('noAccountLinkageForStudent'), type: 'warning', timer: 6000 });
        return;
      }

      // check that account must have be distributable
      if (!this.selectedStudent.isDistributable) {
        this.doneAlert({
            text: this.$t('studentAccountMustAllowDistributeCostCenters', { accountName: this.isRight ? this.selectedStudent.accountNameAr : this.selectedStudent.accountNameEn }),
            type: 'warning',
            timer: 8000
          });
        return;
      }

      // get cost center sub accounts
      this.get({ url: 'CostCenters?type=sub' }).then((data) => {
        this.costCenters = data;
      }).then(() => {
        this.$refs['costCenters-modal'].show();
      });
    },
    closeCostCentersModal() {
      if (this.reminderedCostcenters === 0) {
        this.$refs['costCenters-modal'].hide();
      } else {
        this.doneAlert({ text: this.$t('youShouldDistributeAllAmount'), type: 'error', timer: 6000 });
      }
    },
    removeCostCenter(item) {
      const indx = this.selectedItem.costCenterLines.indexOf(item);
      this.selectedItem.costCenterLines.splice(indx, 1);
    },
    addCostcenter(item) {
      var costCenterItem = this.costCenters.find((val) => val.id === item.CostCenterId);
      var totalCurrentValue = (parseFloat(this.totalCostcenters) + parseFloat(item.value)).toFixed(2);
      var originalValue = parseFloat(this.selectedItem.originalValue) || 0

      if (totalCurrentValue > originalValue) {
        const errorVal = totalCurrentValue - originalValue;
        this.doneAlert({ text: `${this.$t('creditLessThanValueEnteredWith')} ${this.fraction(errorVal)}`, type: 'error' });
        return;
      }
      this.selectedItem.costCenterLines.push({
        costCenterArabicName: costCenterItem.arabicName,
        costCenterEnglishName: costCenterItem.englishName,
        costCenterId: costCenterItem.id,
        costCenterCode: costCenterItem.code,
        TransactionId: this.id,
        lineSerial: this.selectedItem.costCenterLines.length + 1,
        sourceTypeId: 'collectionVoucher',
        ...item,
      });

      // reset
      this.collectionCostCenter = {
        CostCenterId: null,
        value: this.fraction(0),
        notes: ''
      }
    },
  },
};
</script>

<style>
@media (max-width: 768px) {
  .cancel-allocation {
    margin-bottom: 10px;
  }
}

.modal-indicator {
  background-color: #7367f0;
  color: #FFF;
  padding: 5px;
  border-radius: 5px;
  width: 120px;
  text-align: center;
  display: inline-block;
  margin-right: 5px;
}
</style>
